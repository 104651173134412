import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../../utils/rules";
import { notify } from "../../../../redux/actions";
import aictia from "../../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined,SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";

const { Option } = Select;

const Company = (props) => {
  const { t,lang} = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();
 const [disabled, setDisabled] = useState(true);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState([]);
  const [packet, setPacket] = useState([]);
  const [activity, setActivity] = useState([]);
  const [phoneNum, setPhoneNum] = useState(1);
  const [email, setEmail] = useState(1);
  const [ceoPhoneNum, setCeoPhoneNum] = useState(1);
  const [create, setCreate] = useState(true);
  const [ceoEmail, setCeoEmail] = useState(1);
  const [text, setText] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [typeOfCompany, setTypeOfCompany] = useState([]);
  const [tin, setTin] = useState();
  //   const { id } = useParams()
  const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];
  const formRef = useRef();
  // const [fetched, setFetched] = useState(false);
  // const { notify } = props;
  //   console.log(id);
  useEffect(() => {
    if (props.id) {
      const getRegister = () => {
        aictia
          .get(`Organization/${props.id}`)
          .then((res) => {
            setDisabled(false)
            setPhoneNum(res.data.phoneOfCompany.length);
            setEmail(res.data.officialMail.length);
            setCeoPhoneNum(res.data.phoneOfCeo.length);
            setCeoEmail(res.data.mailOfCeo.length);
            setText({ note: res.data.note });
            res.data.socialNetworkofCompany.map((l) => {
            
              form.setFieldsValue({ [`social${l.type}`]: l.value });
            });
            res.data.socialNetworkofCeo.map((l) => {
            
              form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
            });
            form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });

            form.setFieldsValue({
              ...res.data,
              dateOfIncorporationCompany: moment(
                res.data.dateOfIncorporationCompany
              ),
            });
       
            setTin(res.data.tin);
            setSearchData(res.data);
          })
          .catch((err) => {
            props.notify("Xəta baş verdi", false);
          });
      };
      getRegister();
   

      // formRef.current.setFieldsValue(data);
    }
    const getCountry = () => {
      aictia.get("Country").then((res) => {
        setCountry(res.data);
      });
    };
    const getStateOrPrivate = () => {
      aictia.get("StateorPrivate").then((res) => { 
        setState(res.data);
      });
    };
    const getTypeOfActivity = () => {
      aictia.get("TypeOfActivity").then((res) => {
        setTypeOfActivity(res.data);
      });
    };
    const getActivity = () => {
      aictia.get("Activity").then((res) => {
        setActivity(res.data);
      });
    };
    const getAictiaPacket = () => {
      aictia.get("PacketOfAictia").then((res) => {
        setPacket(res.data);
      });
    };
    const getTypeOfCompany = () => {
      aictia.get("TypeOfCompany").then((res) => {
        setTypeOfCompany(res.data);
      });
    };
    getStateOrPrivate();
    getCountry();
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
    getTypeOfCompany();
    //form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.worker, t]);
  
  // useEffect(() => {
  //   if (props.visibleEditWorker && !props.worker) {
  //     form.resetFields();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.visibleEditWorker]);

  const saveItem = (values) => {
    console.log(values);
    
    const media = [
      {
        type: 1,
        socialmeda: "facebook",
      },
      {
        type: 2,
        socialmeda: "twitter",
      },
      {
        type: 3,
        socialmeda: "linkedin",
      },
      {
        type: 4,
        socialmeda: "youtube",
      },
      {
        type: 5,
        socialmeda: "wikipedia",
      },
    ];
    const data = {
      ...values,
      authorizedCapital: String(values.authorizedCapital),
      DateOfIncorporationCompany: values.dateOfIncorporationCompany 
        ? moment(values.dateOfIncorporationCompany).format("YYYY-MM-DD")
        : null,
        packetOfAictiaId:  values.packetOfAictiaId === '' ?  null : values.packetOfAictiaId ,
      SocialNetworkofCompany: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`social${l.type}`],
        };
      }),
      SocialNetworkofCeo: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`ceosocial${l.type}`],
        };
      }),
      TypeOfCompany: Number(values.typeOfCompany),

    };
    if(create){
    if (props.id) {
      aictia
        .put(`Organization/${props.id}`, { ...data, id: props.id })
        .then((res) => {
          form.resetFields();

          props.notify("Deyisdirildi ", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify("Xəta baş verdi", false);
        });
    } else {
      aictia
        .post(`Organization`, data)
        .then((res) => {
          form.resetFields();

          props.notify("Yaradıldı", true);
          window.history.back();
        })
        .catch((err) => {
          console.log(err);
          
          props.notify("Xəta baş verdi", false);
        });
    }
  }else{
    if (props.id) {
      aictia
        .put(`Organization/${props.id}`, { ...data, id: props.id })
        .then((res) => {
          form.resetFields();
          props.notify("Deyisdirildi ", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify("Xəta baş verdi", false);
        });
    } else {
      aictia
        .post(`Organization/${searchData.id}`)
        .then((res) => {
          form.resetFields();
          props.notify("Əlavə olundu", true);
          window.history.back();
        })
        .catch((err) => {
          console.log(err);
          
          props.notify(err, false);
        });
  }

  };
}
  const checkTin = () => {
    const tin = form.getFieldValue("tin");
    aictia.get(`Organization/CheckTIN?TIN=${tin}`).then((res) => {
      setDisabled(true)
      console.log(res);
      
      form.setFieldsValue({
        countryId: res.data.country?.id,
        nameOfCompany: res.data.nameOfCompany,
        typeOfCompanyId: res.data.typeOfCompany?.id,
        dateOfIncorporationCompany: moment(res.data?.dateOfIncorporationCompany),
        officialCEOoftheCompany: res.data?.officialCEOoftheCompany,
        saleSign: res.data.saleSign,
        stateorPrivateId: res.data.stateorPrivate?.id,
        typeOfActivityId: res.data.typeOfActivity?.id,
        activityId: res.data.activity?.id,
        officialWebSite: res.data.officialWebSite,
        authorizedCapital: res.data.authorizedCapital,
        recognizedCeo: res.data.recognizedCeo,
        packetOfAictiaId: res?.data?.packetOfAictia?.id,
        phoneOfCompany: res.data.phoneOfCompany.map((l) => {
          return { value: l.value };
        }),
        officialMail: res.data.officialMail.map((l) => {
          return { value: l.value };
        }),
        phoneOfCeo: res.data.phoneOfCeo.map((l) => {
          return { value: l.value };
        }),
        mailOfCeo: res.data.mailOfCeo.map((l) => {
          return { value: l.value };
        }),
        note: res.data.note,
      });

      res.data.socialNetworkofCompany.map((l) => {
        form.setFieldsValue({ [`social${l.type}`]: l.value });
      }
      );
      res.data.socialNetworkofCeo.map((l) => {
        form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
      });
      setCeoEmail(res.data.mailOfCeo.length);
      setCeoPhoneNum(res.data.phoneOfCeo.length);
      setPhoneNum(res.data.phoneOfCompany.length);
      setEmail(res.data.officialMail.length);
      setText({ note: res.data.note });
      setSearchData(res.data);
      setCreate(false);
    })
    .catch((err) => {
      setDisabled(false)
      setCreate(true);
      form.setFieldsValue({
        countryId: "",
        nameOfCompany: "",
        typeOfCompany: "",
        dateOfIncorporationCompany: "",
        officialCEOoftheCompany: "",
        saleSign: "",
        stateorPrivateId: "",
        typeOfActivityId: "",
        activityId: "",
        officialWebSite: "",
        authorizedCapital: "",
        recognizedCeo: "",
        packetOfAictiaId: "",
        phoneOfCompany: "",
        officialMail: "",
        phoneOfCeo: "",
        mailOfCeo: "",
        note: "",
      });
      
      props.notify("Vöen tapılmadı", false);
      setCeoEmail(1);
      setCeoPhoneNum(1);
      setPhoneNum(1);
      setEmail(1);
      setText({});
      setSearchData([]);
    }
    )
    
  }
  const handleInputClick = (name) => {


    window.open(form.getFieldValue(name), "_blank");
  };
  
  console.log(props.view);
  
  return (
    <div>
      <Form onFinish={saveItem} layout="vertical" form={form}>
        <div className={`commontask ${props.view ? 'detail' : ''}`}>
          <Row gutter={[16, 16]}>
          <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('tin')}
                name="tin"
                
                validateTrigger="onChange"
                rules={[
                  whiteSpace(t("inputError")),
                  {
                    max: 10,
                    message: "10 simvol olmalıdır",
                  },
                  {
                    min: 10,
                    message: "10 simvol olmalıdır",
                  },
                  {
                    pattern: /^-?\d+(\.\d+)?$/,
                    message: "Sadəcə rəqəm olmalıdır",
                  },
                ]}
              >
                <div style={{display:'flex',alignItems:'center', gap:'10px'}}>
                <Input disabled={props.id ? true : false} size={"large"} value={tin}  />
            <Button type="primary" size="large" onClick={checkTin}><SearchOutlined /></Button>
            </div>

              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('country')}
                name="countryId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select disabled={disabled || props.view}>
                  {country.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("nameOfEntity")}
                name="nameOfCompany"
                validateTrigger="onChange"
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input disabled={disabled} readOnly={props.view} size={"large"} />
              </Form.Item>
            </Col>
            
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('businessEntity')}
                name="typeOfCompanyId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select disabled={disabled || props.view}>
                  {typeOfCompany.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("actualAddress")}
                name="actualAddress"
                validateTrigger="onChange"
              >
                <Input size={"large"} readOnly={props.view} disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={ t("legalAddress")}
                name="legalAddress"
                validateTrigger="onChange"
              >
                <Input size={"large"} disabled={disabled} readOnly={props.view} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("employeeCount")}
                name="workerCount"
                validateTrigger="onChange"
              >
                <InputNumber size={"large"} disabled={disabled} readOnly={props.view}/>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('registrationDate')}
                name="dateOfIncorporationCompany"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <DatePicker disabled={disabled} placeholder={t("")} className="w-100" />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('officialRepresentative')}
                name="officialCEOoftheCompany"
                validateTrigger="onChange"
              >
                <Input disabled={disabled} size={"large"} readOnly={props.view}/>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("trademark")}
                name="saleSign"
                validateTrigger="onChange"
              >
                <Input disabled={disabled} size={"large"} readOnly={props.view}/>
              </Form.Item>
            </Col>
         
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("statePrivate")}
                name="stateorPrivateId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select disabled={disabled || props.view}>
                  {state.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('typeOfActivity')}
                name="typeOfActivityId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select disabled={disabled || props.view}>
                  {typeOfActivity.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('fieldOfActivity')}
                name="activityId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select disabled={disabled || props.view}>
                  {activity.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("officialWebsite")}
                name="officialWebSite"
                validateTrigger="onChange"
              >
                <Input disabled={disabled} size={"large"} readOnly={props.view} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("charterCapital")}
                  name="authorizedCapital"
                  validateTrigger="onChange"
                >
                  <InputNumber disabled={disabled} readOnly={props.view} />
                </Form.Item>
                <div className="input-lang">azn</div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("recognizedLeader")}
                name="recognizedCeo"
                validateTrigger="onChange"
              >
                <Input disabled={disabled} size={"large"} readOnly={props.view}/>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t('membershipPackage')}
                name="packetOfAictiaId"
                validateTrigger="onChange"
              >
                <Select disabled={disabled || props.view}>
                  {packet.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t("directorPhone")}</span>
                </div>
                {[...Array(ceoPhoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCeo", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input disabled={disabled} placeholder={"+994XXXXXXXXXX"} readOnly={props.view}/>
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoPhoneNum(ceoPhoneNum + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    disabled={disabled} 
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() =>
                      ceoPhoneNum > 1 && setCeoPhoneNum(ceoPhoneNum - 1)
                    }
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('ceoEmail')}</span>
                </div>
                {[...Array(ceoEmail)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["mailOfCeo", index, "value"]}
                    >
                      <Input disabled={disabled} readOnly={props.view} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoEmail(ceoEmail + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => ceoEmail > 1 && setCeoEmail(ceoEmail - 1)}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('companyPhone')}</span>
                </div>
                {[...Array(phoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCompany", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input disabled={disabled} readOnly={props.view} placeholder={"+994XXXXXXXXXX"} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setPhoneNum(phoneNum + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => phoneNum > 1 && setPhoneNum(phoneNum - 1)}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('officialEmail')}</span>
                </div>
                {[...Array(email)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["officialMail", index, "value"]}
                    >
                      <Input disabled={disabled} readOnly={props.view}/>
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setEmail(email + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => email > 1 && setEmail(email - 1)}
                    type={"primary"}
                    shape={"circle"}
                    disabled={disabled} 
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('companySocialMedia')}</span>
                </div>
                {socialMedia.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input disabled={disabled} readOnly={props.view} onClick={() => props.view ?  handleInputClick(elem.name) : null} />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>{t('directorSocialMedia')}</span>
                </div>
                {socialMediaCeo.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input disabled={disabled} onClick={() => props.view ?  handleInputClick(elem.name) : null} readOnly={props.view}/>
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label={t("note")}
                name="note"
                validateTrigger="onChange"
              >
                <JoditEditor
                  onChange={(newContent) => {
                    form.setFieldsValue({
                      [`note`]: newContent,
                    });
                  }}
                  disabled={disabled}
                  className={"mb-15"}
                  ref={editor}
                  value={text["note"]}
                  tabIndex={1} // tabIndex of textarea
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >
          <Link to={{ pathname: "/organization" }}>
            <Button>{t("cancel")}</Button>
          </Link>
          <Button type="primary" className="ml-10" htmlType="submit">
            {
              create ? t("save") : t("create") 
            }
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default connect(null, { notify })(Company);
