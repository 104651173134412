export let az;
az = {
  // sidebar links
  admin: "İnzibatçı",
  positions: "Vəzifələr",
  permissions: "Səlahiyyətlər",
  users: "İstifadəçilər",
  workers: "İşçilər",
  myCabinet: "Reyestr",
  // bottom menu lang and settings
  langSelection: "Dil seçimi",
  support: "Dəstək",
  instruction: "Istifadə qaydaları",
  termsOfUse: "İstifadə şərtləri",
  feedback: "Geri bildirim",
  dakMode: "Qaranlıq Mod",
  TypeOfProject: "Layihənin növü",
  TypeOfStartup: "Startapın növü",
  TypeOfInnovation: "İnnovasiyanın növü",
  TypeOfInvention:"ixtiranın növü",
  TypeOfProduct:"Məhsulun növü",

//kabinet

typeOfActivity: "Fəaliyyət növü",
fieldOfActivity: "Fəaliyyət sahəsi",
nameOfEntity: "Subyektin adı",
officialRepresentative: "Rəsmi təmsilçi",
field:'Sahə',
tin: "VÖEN",

//edit kabinet

businessEntity: "Sahibkarlıq subyekti",
companyInfo: "Şirkət və fəaliyyəti haqqında məlumatlar",
company: "Şirkət",
businessEntityName: "Sahibkarlıq subyektinin adı",
actualAddress: "Faktiki ünvan",
legalAddress: "Hüquqi ünvan",
employeeCount: "İşçi sayı",
registrationDate: "Qeydiyyat Tarixi",
trademark: "Satış Nişanı",
statePrivate: "Dövlət/Özəl",
officialWebsite: "Rəsmi Web səhifəsi",
charterCapital: "Nizamnamə kapitalı",
recognizedLeader: "Tanınan Rəhbər",
membershipPackage: "Üzvlüklər",
directorPhone: "Rəhbərin telefon nömrəsi",
ceoEmail: "Ceo maili",
companyPhone: "Şirkətin Telefon nömrəsi",
officialEmail: "Rəsmi mail",
companySocialMedia: "Şirkətin sosial mediaları",
directorSocialMedia: "Rəhbərin sosial mediaları",
positions: "Vəzifələr",
permissions: "Səlahiyyətlər",
users: "İstifadəçilər",
workers: "İşçilər",
  // validationMessages
  inputError: "Xana doldurulmalıdır",
  dateError: "Tarix seçilməlidir",
  mailError: "Mail düzgün daxil edilməlidir",
  

  // common page headings
  name: "Adı",
  fullName: "Tam adı",
  fullnameLabel: "Ad, soyad, ata adı",
  officalName: "Rəsmi adı",
  phone: "Telefon",
  contactNumber: "Əlaqə nömrəsi",
  country: "Ölkə",
  city: "Şəhər",
  adress: "Ünvan",
  industry: "Sənaye",
  relatedPerson: "Əlaqəli şəxs",
  eMail: "E-poçt",
  email: "Email",
  addTo: "Əlavə et",
  edit: "Redaktə et",
  delete: "Sil",
  save: "Yadda saxla",
  cancel: "Ləğv et",
  approved: "Təsdiq edilib",
  accepted: "Qəbul edilib",
  doConfirm: "Təsdiq et",
  canceled: "Ləğv edilib",
  onWait: "Gözləyən",
  close: "Bağla",
  type: "Növ",
  typeOf: "Növü",
  note: "Qeyd",
  additionalNote: "Əlavə qeyd",
  quantity: "Miqdar",
  quantityOf: "Miqdarı",
  productName: "Məhsulun adı",
  product: "Məhsul",
  heading: "Başlıq",
  detailed: "Ətraflı bax",
  detailedInfo: "Ətraflı məlumat",
  selectDate: "Tarixi seçin",
  areYouSure: "Silmək istədiyinizə əminsinizmi ?",
  yes: "Bəli",
  no: "Xeyr",
  // positions page
  responsibility: "Cavabdehlik",
  positionName: "Vəzifənin adı",

  // permissions page
  authorityGroups: "Səlahiyyət qrupları",
  read: "Oxumaq",
  write: "Yazmaq",
  editTo: "Redaktə etmək",
  deleteTo: "Silmək",

  // userspage
  username: "İstifadəçi adı",
  newPassword: "Yeni şifrə",
  worker: "İşçi",
  positionGroup: "Vəzifə qrupu",
  // areas page
 
  company: "Şirkət",
  position: "Vəzifə",

  // client-and-consumers
  allOf: "Hamısı",
  status: "Status",

  fin: "Ş/V fin kodu",
  seriaNo: "Ş/V seriya nömrəsi",
  birthdate: "Doğum tarixi",
  sex: "Cinsi",

  // warehouse/purchases
  changeStatus: "Statusu dəyiş",
  dateToBuy: "Alınma tarixi",
  endDate: "Bitmə tarixi",
  startDate: "Başlama tarixi",
  price: "Qiymət",
  finalPrice: "Yekun qiymət",
  search: "Axtar",

  // warehouse/purchases-on-wait
  using: "İstifadə",
  operation: "Əməliyyat",

  // eservespage
  acceptedDate: "Qəbul tarixi",
  // daily page
  date: "Tarix",

  // new added words
  savedMessage: "Dəyişikliklər yadda saxlanıldı",
  addPasswordError: "Şifrəni təyin edin",
  minimumPasswordError: "Şifrə minimal 6 simvol olmalıdır",
  workerMustSelectError: "İşçi seçilməlidir",
  permissionMustSelectError: "Səlahiyyət növü seçilməlidir",
  itemMustSelectError: "Maddə seçilməlidir",
  measurementUnitMustSelectError: "Ölçü vahidi seçilməlidir",
  typeMustBeSelected: "Növ seçilməlidir",
  positionMustSelectError: "Vəzifə seçilməlidir",
  regionMustSelectError: "Region seçilməlidir",
  categoryMustSelectError: "Kateqoriya seçilməlidir",
  selectCropSort: "Sahə növü seçilməlidir",
  selectCrop: "Sahə seçilməlidir",
  addNewArea: "Yeni sahə əlavə et",
  crops: "Crops",
  successMessage: "Əməliyyat uğurlu oldu.",
  errMessage: "Əməliyyat uğursuz oldu.",
  errorMessage: "Xəta baş verdi",
  workerDeleted: "İşçi sistemdən silindi.",
  workerIsAdded: "Yeni işçi yaradıldı.",
  addNewWorker: "Yeni işçi əlavə et",
  man: "Kişi",
  woman: "Qadın",
  thereIs: "Var",
  thereNo: "Yoxdur",
  notifications: "Bildirişlər",
  login: "Daxil ol",
  register: "Qeydiyyatdan keç",
  personCount: "nəfər",
  logOut: "Çıxış",
  password: "Şifrə",
  EMailFull: "Elektron poçt",
  // permissions
  administrator: "İnzibatçı",
  create: "Əlavə etmək",
  update: "Yeniləmək",

  links: {
    admin: "inzibatci",
    positions: "vezifeler",
    permissions: "selahiyyetler",
    users: "istifadeciler",
    workers: "ishciler",
    history: "tarixce",
    // new added
    reports: "hesabatlar",
  },
  // layout pages
  // home
 
  

  remember:'Yadda saxla',
  entry: 'GİRİŞ',
};


