export const ru = {
  // sidebar links
  admin: "Администратор",
  positions: "Должности",
  permissions: "Обязанности",
  myCabinet: "Реестр",
  users: "Пользователи",
  storageSettings: "Регулирование складов",
  areas: "Земельные участки",
  typeAndConditions: "Виды и условия",
  clientAndConsumers: "Клиенты и поставщики",
  todos: "Работы, которые будут выполнены",
  humanResources: "Человеческие ресурсы",
  workers: "Рабочие",
  dependencyTable: "Табель посещяемости",
  warehouse: "Склад",
  demands: "Требования",
  purchases: "Купля-продажа",
  purchasesOnWait: "Товар в ожидании",
  drugAndFertilizersWarehouse: "Склад лекарств и удобрений",
  productWarehouse: "Склад продуктов",
  reserveWarehouse: "Склад запасов",
  history: "История",
  businessPlanning: "Рабочий план",
  daily: "Ежедневный",
  yearly: "Годовой",
  financeAdmin: "Финансовый-администратор",
  operationTypes: "Виды операций",
  paymentTypes: "Виды платежей",
  accountTypes: "Банк/типы счетов",
  areaNames: "Название Участков",
  customers: "Клиенты",
  operationPoints: "Детали операции",
  sectors: "Секторы",
  sector: "Сектор",
  dailyFinancialReports: "Ежедневные финансовые расчеты",
  reports:'Отчеты',
//kabinet
fieldOfActivity: "Сфера деятельности",
typeOfActivity: "Вид деятельности",
officialRepresentative: "Официальный представитель",
nameOfEntity: "Имя субъекта",
field:'Сфера',
tin: "ИНН",


  // bottom menu lang and settings
  langSelection: "Выбор языка",
  support: "Поддержка",
  instruction: "Правило пользования",
  termsOfUse: "Услови пользования",
  feedback: "Обратная связь",
  dakMode: "Темный мод",

  // validationMessages
  inputError: "Ячейка должна быть заполнена",
  dateError: "Дата должна быть заполнена",
  mailError: "Почта должна быть введена правильно",

  // common page headings
  name: "Имя",
  fullName: "Полное Имя",
  fullnameLabel: "Имя, Фамилия, Очество",
  officalName: "Официальное название",
  phone: "Телефон",
  contactNumber: "Контактный номер",
  country: "Страна",
  city: "Город",
  adress: "Адрес",
  industry: "Промышленность",
  relatedPerson: "Связнной",
  eMail: "E-почта",
  email: "Email",
  addTo: "Добавить",
  edit: "Редактировать",
  delete: "Удалить",
  save: "Сохранить",
  cancel: "Отменить",
  approved: "Потверждено",
  accepted: "Принято",
  doConfirm: "Поивердить",
  canceled: "Отменено",
  onWait: "В ожидании",
  close: "Закрыть",
  type: "Вид",
  typeOf: "Тип",
  note: "Примечание",
  additionalNote: "Дополнительное примечание",
  quantity: "Количество",
  quantityOf: "Количетсво",
  productName: "Название товара",
  product: "Товар",
  heading: "Заголовок",
  detailed: "Смотреть подробности",
  detailedInfo: "Подромная информация",
  selectDate: "Выбери дату",
  areYouSure: "Вы уверены что хотите удалить ?",
  yes:'Да',
  no:'Нет',
  // positions page
  responsibility: "Ответсвтенность",
  positionName: "Название должности",
  companyInfo: "Информация о компании и её деятельности",
  company: "Компания",
  businessEntityName: "Название юридического лица",
  businessEntity: "Субъект предпринимательства",
  actualAddress: "Фактический адрес",
  legalAddress: "Юридический адрес",
  employeeCount: "Количество сотрудников",
  registrationDate: "Дата регистрации",
  trademark: "Торговая марка",
  statePrivate: "Государственный/Частный",
  officialWebsite: "Официальный сайт",
  charterCapital: "Уставной капитал",
  recognizedLeader: "Признанный лидер",
  membershipPackage: "Пакет членства",
  directorPhone: "Телефон руководителя",
  ceoEmail: "Электронная почта гендиректора",
  companyPhone: "Номер телефона компании",
  officialEmail: "Официальная почта компании",
  companySocialMedia: "Социальные сети компании",
  directorSocialMedia: "Социальные сети директора",
  note: "Примечание",
  positions: "Должности",
  permissions: "Полномочия",
  users: "Пользователи",
  workers: "Работники",
  feedback: "Обратная связь",
  // permissions page
  authorityGroups: "Группы полномочий",
  read: "Читать",
  write: "Ввести",
  editTo: "Редактировать",
  deleteTo: "Удалить",
    
  readSectors: "View sectors",
  readCrops: "View crops",
  addCrop: "Add crop",
  deleteCrop: "Delete crop",
  updateCrop: "Update crop",

  hr: "Человеческие ресурсы.",
  readSalaryHistory: "View salary history",
  createSalaryHistory: "Add to salary history",

  // userspage
  username: "Имя пользователя",
  newPassword: "Новый пароль",
  worker: "Работник",
  positionGroup: "Группа должностей",

  // storage-settings
  warehouses: "Склады",
  warehouseName: "Название складов",
  foodProducts: "Продукты питания",
  drugAndFertilizers: "Лекарство и удобрение",
  drugAndFertilizer: "Лекарство и удобрение",
  activeSubstance: "Действущее вещество",
  activeSubstances: "Действущие вещества",
  activeSubstanceName: "Название действущего вещества",
  fertilizer: "Удобрение",
  medicine: "Лекарство",
  kilograms: "Килограмм",
  meters: "Метр",
  liter: "Литр",
  pieces: "Штук",
  hundredliterRatio: "Соотношение 100л",
  measurementUnit: "Единица измерения",
  plantProducts: "Растительные продукты",
  plantProductsSorts: "Виды растительных продуктов",
  reproduction: "Репродукция",
  reserves: "Запасы",
  reserv: "Запас",
  reservName: "Название заповедника",
  addReserve: "Добавить резерв",

  // areas page
  LandAreaRegulations: "Регуляторы земельных участков",
  region: "Регион",
  category: "Категория",
  areaName: "Название участка",
  area: "Участок",
  treeNumber: "Число деревьев",
  respondentPeople: "Ответсвенное лицо",
  respondent: "Отвественный",
  company: "Фирма",
  position: "Должность",
  createdDate: "Дата создания",

  // typeAndConditions page
  paymentRules: "Правила платежа",
  deliveryTerms: "Условия доставки",

  // client-and-consumers
  allOf: "Все",
  client: "Клиент",
  clients: "Клиенты",
  consumer: "Поставщик",
  agreementDocumentNumber: "Номер договорного документа",
  agreementDocumentDate: "Дата договорного документа",
  paymentTerm: "Условия платежа",
  paymentType: "Вид платежа",
  paymentTime: "Срок оплаты",
  deliveryDate: "Срок доставки",
  deliveryTern: "Условия доставки",
  status: "Статус",
  advancePaymentCondition: "Условие авансового платежа",
  advanceCondition: "Условие аванса",
  advancePaymentType: "Вид авансового платежа",
  advancePaymentTime: "Срок авансового платежа",

  // human-resources/workers
  workStartDate: "Дата начало работы",
  totalSalary: "Общая заработная плата",
  salary: "Заработная плата",
  salaryForm: "Форма заработная плата",
  salaryType: "Форма оплаты",
  dailySalary: "Плата за день",
  salaryForWork: "Наем работы",
  fin: "Fin код",
  seriaNo: "Серия №",
  birthdate: "Дата Рождения",
  sex: "Пол",

  // warehouse/demands
  demand: "Требование",
  demmandNo: "Требование №",
  demandType: "Вид Требования",
  demandObject: "Объект требования",
  demandName: "Название требования",
  demandedPerson: "Требователь",
  countryOfOrigin: "Произведено в стране",
  originCountry: "Страна производитель",
  dateMustBuy: "Дата покупки",
  expirationDate: "Дата последнего использования",
  areaCategory: "Поля категорий",

  // warehouse/purchases
  approveds: "Потвержденный",
  preparedDocuments: "Подготовленные документы",
  changeStatus: "Изменить статус",
  dateToBuy: "История приобретения",
  endDate: "История окончания",
  startDate: "Дата начала",
  supplier: "Поставщик",
  supplierConnection: "Подключение поставщика",
  DateOfApproval: "Дата утверждения",
  approvedPerson: "Подтверждающий",
  createPurchaseDocument: "Создать документ о покупке",
  createNewPurchaseDocument: "Создать новый документ о покупке",
  viewPurchaseDocument: "Посмотреть документ о покупке",
  customsCosts: "Таможенные расходы",
  customsDuties: "Таможенная пошлина",
  transportCosts: "Транспортные расходы",
  transportDuties: "Транспортная пошлина",
  mustDeliverDate: "Дата доставки",
  lastPaymentDate: "Последняя дата оплаты",
  productType: "Тип продукта",
  productCategory: "Категория продукта",
  productSorts: "Сорт продукта",
  price: "Цена",
  discount: "Скидка",
  AdditionalTax: "Налог на добавленную стоимость",
  finalPrice: "Конечная цена",
  activityOf: "Деятельность",
  extraIncome: "Дополнительный доход",
  search: "Поиск",
  approximateTransportFee: "Транспортные расходы",
  ApproximatePriceProducts: "Примерная цена продукции",

  // warehouse/purchases-on-wait
  addToReserves: "Добавить в резервы",
  barCode: "Штрих-код",
  // drug-and-fertilizers
  using: "Использование",
  documentNumber: "Номер документа",
  documents: "Документы",
  inReserves: "В запасе",
  import: "Доходы",
  export: "Расходы",
  tasks: "Задании",
  task: "Задание",
  operation: "Оперции",
  mainIngridient: "Главный пункт",
  print: "Печать",
  person: "Персона",

  // warehouse/products
  sale: "Продажа",
  sortOf: "Сорт",
  barCodeOf: "Баркод",
  areaType: "Вид отрасли",
  handingOver: "Сдача",
  carNumber: "Номер машины",
  addNewProduct: "Добавить новый продукт",
  addProduct: "Добавить продукт",
  acceptedPerson: "Приниматель",
  sendedPerson: "Передатель",
  acceptedCarNumber: "N/V номер принимателя",
  productQuantity: "Количество товаров",
  importExportDocuments: "Документы доходов и расходов",

  // eservespage
  acceptedDate: "Дата принятия",

  // historyPage
  operationHistory: "История операций",
  buyer: "Покупатель",

  // daily page
  dailyPlaning: "Управление ежедневными планами работы",
  finishedPlans: "Готовые планы работы",
  WorkPlanName: "Название плана работы",
  yearlyWorkPlan: "Ежегодное планирование работы",
  totalArea: "Общая площадь",
  workWillDo: "Запланированные задачи",
  manWorkerNumber: "Количество рабочих мужчин",
  womanWorkerNumber: "Количество женщин-работников",
  workerNumber: "Количество сотрудников",
  addtask: "Добавить задачу",
  addMedicine: "Добавить лекарства",
  finalAmount: "Итоговое количество",
  notStartedTasks: "Не выполненные задачи",
  tasksOnDone: "Выполненные задачи",
  finishedTasks: "Готовые задачи",
  date: "История",

  // yearly
  yearlyPlaning: "Управление годовыми планами работы",
  parsel: "Участок",
  periods: "Периоды",
  period: "Период",
  addSector: "Добавить сектор",
  addPeriod: "Добавить период",
  workPlanWillDone: "План работы",
  workWillDone: "Зпланированная работа",
  addWorkWillDonw: "Добавить запланнированную работу",
  operationItem: "Пункт операций",
  bankAccountName: "Банк/Название счетов",

  // daily financial reports
  dailyreports: "Ежедневный финансовый отчет",
  report:'Отчет',

  // new added words
      savedMessage:'Сохранить изменения',
      addPasswordError:'Создайте пароль',
      minimumPasswordError:'Минимальное введение символов 6',
      workerMustSelectError: "Работник должен быть выбран",
      permissionMustSelectError: "Тип полномочий должен быть выбран",
      itemMustSelectError: "Пункт должен быть выбран",
      measurementUnitMustSelectError: "Единица измерения должна быть выбрана",
      typeMustBeSelected: "Тип должен быть выбран",
      positionMustSelectError: "Должность должна быть выбрана",
      regionMustSelectError: "Регион должен быть выбран",
      categoryMustSelectError: "Категория должна быть выбрана",
      selectCropSort: "Тип поля должно быть выбрано",
      selectCrop: "Поле должно быть выбрано",
      addNewArea: "Добавить новую область",
      crops:'Товары',
      successMessage: "Операция была успешной.",
      errMessage: "Операция провалилась.",
      errorMessage: "Ошибка произошла",
      newDemandCreated: 'Новый запрос был создан.',
      newDocumentCreated: 'Новый документ создан.',
      areaIsAdded: 'Поле было добавлено.',
      importIsAdded: 'Новый медахил.',
      productIsAdded: "Продукт добавлен на склад",
      planIsAdded: 'План был добавлен.',
      clientIsAdded: 'Клиент был добавлен.',
      clientDeleted: 'Клиент удален.',
      workerDeleted: 'Работник удален из системы.',
      workerIsAdded: 'Создан новый сотрудник.',
      addNewWorker: "Добавить новых сотрудников",
      sectorName:'Название сектора',
      sectorArea:'Область сектора',
      products:'Товары',
      addNewClient: "добавить нового клиента/поставщика",
      operations: "Операции",
      salarixistory: "история заработной платы",
      man:'Мужчины',
      woman:'Женщины',
      createNewDemand: "Создать новый запрос",
      moneyQuantity:'Сумма',
      operationType:'Вид операции',
      thereIs:'Есть',
      thereNo:'Нету',
      ApproximatecustomsCosts: "Примерная таможенная пошлина",
      barcodeMustAdd: "Штрих-код должен быть включен",
      warehouseMustSelect: "Склад должен быть выбран",
      quantityMustAdd: "Количество должно быть включено",
      isUsed: 'Используется',
      isNotUsed: "Не используется" ,
      newehoort: "Новый магарик",
      areasSector: "Сектор поля",
      productMustSelect: "Продукт должен быть выбран",
      areyousurindtask: "Хотите закончить задачу?",
      endTask: 'Конец задачи',
      newReport: "Новый отчет",
      shortStory: "Краткое описание",
      rejected: "Отклонено",
      isEndsDate: "Дата истечения срока действия",
      createNewTask: "Создать новую задачу",
      settings:'Настройки',
      notifications:'Уведомление',
      login:'Войти',
      register:'Пройти регистрацию',
      activeWorkPlans: "Активные планы работы",
      createNewWorkPlan: "Создать новый бизнес-план",
      mustSelectWorkPlan: "Планирование работы должно быть выбрано",
      sectorMustSelect: "Сектор должен быть выбран",
      workMustSelect: "Работа должна быть выбрана",
      workWillMustSelect: "Работа должна быть выбрана",
      personMustSelect: "Человек должен быть выбран",
      numberMustAdd:'Введите число',
      medicines:'Лекарство',
      personCount:'Количество',
      logOut:'Выход',
      password: 'Пароль',
      EMailFull:'Электронна почта',

  links: {
    admin: "администратор",
    positions: "должностное-положение",
    permissions: "разрешения",
    users: "пользователи",
    storageSettings: "хранение-настройки",
    areas: "области",
    typeAndConditions: "виды-и-условия",
    clientAndConsumers: "клиент-и-потребители",
    todos: "запланированный-задачи",
    humanResources: "человеческие-ресурсы",
    workers: "рабочие",
    dependencyTable: "рабочий-табель",
    warehouse: "склад",
    demands: "требования",
    purchases: "покупки",
    purchasesOnWait: "ожидаемые-покупки-и-продажи",
    drugAndFertilizersWarehouse: "лекарственные-препараты-и-удобрения",
    productWarehouse: "продукты",
    reserveWarehouse: "резервы",
    history: "история",
    businessPlanning: "рабочий-план",
    daily: "ежедневный",
    yearly: "годовой",
    financeAdmin: "финансовый",
    operationTypes: "вид-операции",
    paymentTypes: "виды-платежа",
    accountTypes: "вид-платежа",
    areaNames: "названия-районов",
    customers: "клиенты",
    operationPoints: "рабочая-точка",
    sectors: "секторы",
    dailyFinancialReports: "ежедневные-финансовые-отчеты",
    reports:'отчеты',
  },

    tools: "Alətlər",
    tool: "Alət",

    // layout pages
    // home
    slide1: 'Централизованная система управления агропарками.',
    subslide1: 'Целью данной программы является система управления агропарками и сельским хозяйством, позволяющая эффективно управлять и оптимизировать свой бизнес.',

    slide2: 'Азербайджанский продукт.',
    subslide2: 'Программное обеспечение  основана на внутреннем опыте. Цель нашей команды-создать Азербайджанский продукт. Мы шаг за шагом приближаемся к этой цели.',

    slide3 : 'Программное обеспечение, которое постоянно обновляется и адаптируется.',
    subslide3: 'Программа обновляется и адаптируется с учетом пожеланий клиентов, чтобы наши клиенты могли преодолевать трудности и эффективно решать проблемы в агропарке. С помощью вас наша программа становиться более эффективнее.',


    layoutLinks: {
        home: 'ГЛАВНАЯ',
        about : 'О НАС',
        products : 'ПРОДУКТЫ\n',
        packs : 'ПАКЕТ',
    },
    aboutCompany: 'Начиная с 2018 года, TimeSoft является одной из ведущих IT-компаний в Азербайджане. Деятельность нашей компании служит для автоматизации процессов работы, на различных предприятиях Азербайджана. Целью наших продуктов являетсяПовышение производительности предприятия Помочь принять эффективные решения Также оптимизации выполняемых процессов.В сфере бизнеса и государственного управления. Эффективное использование информационных технологий помогает достичь этих целей экономит время и повышает эффективность производство товаров и услуг. За короткое время мы подписали ряд успешных проектов в этой области и разработали ряд новых платформ в нашей стране.',
    aboutAgros:'   Обострения конкуренции в экономической системе является нормой. Из за этого предприятии, чтобы выжить в такой тяжелой рыночной среде, все больше бегут за производительностью, для этого повышают степень автоматиз ации фирмы, для сокращения своих издержек. Видя суть проблемы и спрос предпринимательского общества создали эту программу, где вы можете: 1)управлять 2)контролировать, 3) анализировать и узнать состояние вашего предприятия в режиме онлайн. Даже находясь за границей, вы спокойно можете знать текущею ситуацию ваших технологий, запасов, ресурсов, документаций, посещаемость рабочих и т.д',
    aboutAgros2:'Это будет большим подспорьем в управлении работой фермеров и фермеров, работающих в сфере сельского хозяйства. Например, АгроПарк облегчает всю работу, полностью отражая планирование посадки, планирование работы, системы орошения, складские помещения и ресурсы, человеческие ресурсы и финансы. Путем интеграции других сервисов, таких как Камера, Турникет, Отслеживание трафика, они смогут планировать и управлять своими территориями и транспортными средствами на карте.',


    aboutProduct:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui faucibus in ornare quam viverra. Sit amet aliquam id diam maecenas ultricies mi eget mauris. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Ac turpis egestas integer eget aliquet nibh praesent tristique. Nibh molestie a iaculis at erat pellentesque. Urna nunc id cursus metus aliquam eleifend mi. Consequat semper viverra nam libero justo. Gravida rutrum quisque non tellus orci ac. Diam donec adipiscing tristique risus nec feugiat in',
    aboutHr:'В разделе HR хранятся личные данные всех сотрудников. В этом модуле предусмотрено разделение труда сотрудников, автоматизация начисления заработной платы сотрудникам, работающим на местах. Также в соответствии с базой данных отражаются статистические отчеты. После того, как сотрудники включены в систему, историческая информация о них сохраняется.',
    aboutManagement:' Одной из основных задач агропарка являются полевые работы. С помощью продукта "управление задачами" вы можете увидеть эффективность выполнения задач сотрудников. В этом модуле вы создаете годовой план, а затем на основе годового плана создаете еженедельный план, по оценке выполнения которого лицо, ответственное за задачу, пишет отчет. В статистике вы можете сравнить годовой план, ежедневный план и отчет, предоставленный после выполнения задачи, сколько ресурсов было использовано. Если ресурсов не хватает, то происходит переход к закупочным и складским процессам.',
    aboutWarehouse:' В этом модуле показаны все процессы закупок и складирования агропарка. Сначала создается спрос, потом идет покупка, а после подтверждения товар кодируется штрих-кодом и поступает на склад. На странице склада вы можете ввести продукт, вывести продукт и перенести продукт на другой склад.',

    // headings
    manegment:'Управление задачами',
    // packs page
    garage: 'Гараж',
    storage: 'Памят',
    user:'Пользователь',
    userCost:'Сумма для пользователя',
    storageCost:'Сумма хранилища',
    packCost:'Сумма пакета',
    additionalCost:'Дополнительная сумма',
    finalCost:'Окончательная сумма',
    continue: 'Продолжение',
    companyInfo: 'Информация о компании',
    companyName: 'Название компании',
    companyEmail:'Электронная почта компании',
    remember:'Запомнить',
    entry: 'ВХОД',

};
