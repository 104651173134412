import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Select, Input, Button, DatePicker, Form, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../const/history";
import { items, socialMedia, socialMediaCeo } from "../../StatikData/data";
import ContactCard from "../../Card/contactCard";
import {
  UsergroupAddOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const ViewOrganization = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();

  const [data, setData] = useState([]);
  const [typeOfCompany, setTypeOfCompany] = useState(null);
  const { id } = useParams();

  const [show, setShow] = useState(false);

  const [spin, setSpin] = useState(false);

  useEffect(() => {
    setSpin(true);
    aictia.get(`Organization/details/${id}`).then((res) => {
      console.log(res);
      
      // const viewData = res?.data?.find((item) => item.id == id);
       setData(res.data);
      setSpin(false);

      items.map((item) => {
        if (item.item == res.data?.typeOfCompany) {
          setTypeOfCompany(item.code);
        }
      });
    });
  }, []);

  const details = [
    { title: t('country'), content: data?.country?.name },
    { title: t('nameOfEntity'), content: data?.nameOfCompany },
    {
      title: t('officialRepresentative'),
      content: data?.officialCEOoftheCompany,
    },
    { title: t("statePrivate"), content: data?.stateorPrivate?.name },
    { title: t('businessEntity'), content: typeOfCompany },
    {
      title: t('registrationDate'),
      content: moment(data?.dateOfIncorporationCompany).format("YYYY-MM-DD"),
    },
    { title: t('tin'), content: data?.tin },
    { title: t('type'), content: data?.typeOfActivity?.name },
    { title: t('field'), content: data?.activity?.name },
    { title: t("actualAddress"), content: data?.actualAddress ? data?.actualAddress : "Ünvan daxil edilməyib" },
    { title: t("legalAddress"), content: data?.legalAddress ? data?.legalAddress : "Ünvan daxil edilməyib" },
    { title: t("employeeCount"), content: data?.workerCount? data?.workerCount : "İşçi sayı daxil edilməyib" },
    { title: t("trademark"), content: data?.saleSign },
    { title: t("charterCapital"), content: data?.authorizedCapital },
    {
      title: "Akitsa üzvülük paketi",
      content: data?.packetOfAictia?.name
        ? data?.packetOfAictia?.name +
          "( " +
          data?.packetOfAictia?.price +
          " manat )"
        : "Paket seçilməyib",
    },
  ];

  const visibleDetails = show ? details : details.slice(0, 5);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UsergroupAddOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Şirkət məlumatları</span>
            </div>
            <Link
              to={{
                pathname: `/organization`,
              }}
            >
              <Button type={"primary"}>X</Button>
            </Link>
          </div>
        </Col>
        {spin ? (
         <Col>
          <div className="flex flex-align-center justify-center mt-15">
            <Spin />
          </div>
         </Col>
        ) : (
          <>
            <Col xs={24} md={12}>
              <div className="detail-info">
                <div>
                  <h2 className="detail-title">{t('businessEntity')}</h2>

                  {visibleDetails.map((detail, index) => (
                    <div key={index} className="flex flex-between w-100 mb-15 pr-1">
                      <p className="detail-left">{detail.title}</p>
                      <p className="detail-right ">{detail.content}</p>
                    </div>
                  ))}
                </div>
                <div className="down" onClick={() => setShow(!show)}>
                  {show ? <UpOutlined /> : <DownOutlined />}
                </div>
              </div>
            </Col>

            <Col xs={24} md={12}>
              <ContactCard data={data} />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default connect(null, { notify })(ViewOrganization);
